import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
import LineItem from "./LineItem";
import ResumeCard from "./ResumeCard";

const TermsandConditions = () => {
  const { lineArray, resumeArray } = UseData();

  return (
    <>
      <PageTitle title="Foxy's World"></PageTitle>
      {/* End pagetitle */}

      <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container  sm:px-5 md:px-10 lg:px-20">
            <div className="py-12 px-12">
              {/*page title here */}
              <h2 className="after-effect  mb-[40px] mt-12 lg:mt-0">
              Terms of Service
              </h2>
              <div className="">
                {/* resume items map */}
                <p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>The terms of this agreement ("Terms of Service") govern the relationship between you and NeonBlast, (hereinafter "NeonBlast" or "Us" or "We") regarding your use of NeonBlast's games, store, sites and related services (the "Service"). Use of the Service is also governed by NeonBlast's Privacy Policy and other relevant policies, which are incorporated herein by reference. 

Before accessing or using the Service, including browsing any NeonBlast website or accessing a game, you must agree to these Terms of Service and the Privacy Policy. A guest account may be created for you for the use of the Service, and you may also be required to register an account on the Service (collectively "Account"). These Accounts include, for example, game Accounts and NeonBlast ID. By using or registering for an Account or otherwise using the Service, you affirm that you are the legal age of majority in your country of residence. If you are not, your legal guardian must review and agree to these Terms of Service.

BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
IMPORTANT NOTICE: For U.S. and Canadian residents, you also agree that disputes with NeonBlast must be resolved on an individual basis through final and binding arbitration as described in Section 8 (“Dispute Resolution”).

NeonBlast reserves the right, at its discretion, to change, modify, add or remove portions of these Terms of Service, its Privacy Policy and other relevant NeonBlast policies at any time by posting the amended terms on the Service. You will be deemed to have accepted such changes by continuing to use the Service. If at any point you do not agree to any portion of the then-current version of our Terms of Service, the NeonBlast Privacy Policy, or any other NeonBlast policy, rules or codes of conduct relating to your use of the Service, your right to use the Service shall immediately terminate, and you must immediately stop using the Service.
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>1. Using the Service</h1>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>1.1. Your Right to Use the Service</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Subject to your agreement and continuing compliance with these Terms of Service and any other relevant NeonBlast policies, you have a non-exclusive, non-transferable, non-sublicensable, revocable and limited right to access and use the Service for your own non-commercial entertainment purposes. You agree not to use the Service for any other purpose.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The following restrictions apply to the use of the Service:

You accept full responsibility for any unauthorized use of the Service by minors. You are responsible for any use of your credit card or other payment instrument (such as PayPal) by minors.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You shall not (or attempt to) purchase, sell, rent or give away your Account, create an Account using a false identity or information, or on behalf of someone other than yourself; You shall not use the Service if you have previously been removed by NeonBlast, or previously been banned from playing any NeonBlast game.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You shall not use the Service to advertise, or solicit, or transmit any commercial advertisements, including chain letters, junk, or spam e-mail or repetitive or misleading messages to anyone.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Login information and Your Account
You may be required to select a password for your Account, or you may also use other credentials to access the Account ("Login Information"). You shall not share the Account or the Login Information, nor let anyone else access your Account or do anything else that might jeopardize the security of your Account. In the event you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the Login Information, you must immediately notify NeonBlast and modify your Login Information. You are solely responsible for maintaining the confidentiality of the Login Information, and you will be responsible for all uses of the Login Information, including purchases, whether or not authorized by you. You are responsible for anything that happens through your Account.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to claims by a third party that a username violates the third party's rights.

Unless you are using NeonBlast ID, the Service supports only one Account per game on a supported device. 
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast ID
NeonBlast ID is a service that allows you to safeguard your game account and easily play the NeonBlast games with your account on all of your mobile devices. In order for us to safeguard your account security and privacy, You will not be able to disconnect NeonBlast ID from your game account, once You sign up for NeonBlast ID. If you have any questions about NeonBlast ID, You can contact us through the support features within each NeonBlast game.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Use Limitations
Any use of the Service in violation of these Use Limitations is strictly prohibited, can result in the immediate revocation of your limited right and may subject you to liability for violations of law.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You agree that you will not, under any circumstances:

Engage in any act that NeonBlast deems to be in conflict with the spirit or intent of the Service or make improper use of NeonBlast's support services.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Use or take part (directly or indirectly) in the use of cheats, exploits, automation software, emulators, bots, hacks, mods or any unauthorized third-party software designed to modify or interfere with the Service, any NeonBlast game or any NeonBlast game experience.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Modify or cause to be modified any files that are a part of the Service or any NeonBlast game without NeonBlast's express written consent.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Disrupt, interfere with or otherwise adversely affect the normal flow of the Service or otherwise act in a manner that may negatively affect other users' experience when using the Service or playing NeonBlast's games. This includes win trading and any other kind of manipulation of rankings, taking advantage of errors in the Service to gain an unfair edge over other players and any other act that intentionally abuses or goes against the design of the Service.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Disrupt, overburden, or aid or assist in the disruption or overburdening of any computer or server used to offer or support the Service or any NeonBlast game environment.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Institute, assist, or become involved in any type of attack, including without limitation distribution of a virus, denial of service attacks upon the Service, or other attempts to disrupt the Service or any other person's use or enjoyment of the Service.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Attempt to gain unauthorized access to the Service, Accounts registered or used by others or to the computers, servers, or networks connected to the Service by any means other than the user interface provided by NeonBlast, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that is part of the Service.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable or offensive or engage in ongoing toxic behavior, such as by repeatedly posting information on an unsolicited basis.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Post any information that contains nudity, excessive violence, or offensive subject matter or that contains a link to such content.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Attempt to, or harass, abuse, or harm, or advocate or incite harassment, abuse, or harm of another person, group, including NeonBlast employees, including NeonBlast's customer service representatives.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Make available through the Service any material or information that infringes any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or other right of any person or entity or impersonates any other person, including without limitation a NeonBlast employee.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying software or other intellectual property used to provide the Service or any NeonBlast game, or to obtain any information from the Service or any NeonBlast game using any method not expressly permitted by NeonBlast.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Solicit, or attempt to solicit, Login Information or any other login credentials or personal information from other users of the Service or any NeonBlast game.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Collect or post anyone's private information, including personally identifiable information (whether in text, image or video form), identification documents, or financial information through the Service.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Use any NeonBlast game for gambling, betting or any similar activity in which prizes or rewards can be won (directly or indirectly), including betting on the outcome of matches in which you participate as a player, irrespective of whether or not there is a fee or stake involved.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Use the Service in any way that would violate any export controls, anti-money laundering rules, economic sanctions or similar laws or regulations including but not limited to those imposed by the United States of America and/or the European Union.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast reserves the right to determine what conduct it considers to be in violation of the rules of use or otherwise outside the intent or spirit of these Terms of Service or the Service itself. NeonBlast reserves the right to take action as a result, which may include terminating your Account and prohibiting you from using the Service in whole or in part.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>1.2. Suspension and Termination of Account and Service</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
WITHOUT LIMITING ANY OTHER REMEDIES, NeonBlast MAY LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS THEREOF WITH OR WITHOUT NOTICE TO YOU (i) IF YOU ARE, OR NeonBlast SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH THESE TERMS OF SERVICE; OR (ii) FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE OF THE SERVICE. YOU CAN LOSE YOUR USERNAME AND PERSONA IN THE SERVICE AS A RESULT OF ACCOUNT TERMINATION OR LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED VIRTUAL ITEMS AND PURCHASED VIRTUAL ITEMS ASSOCIATED WITH YOUR USE OF THE SERVICE, AND NeonBlast IS UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR RESULTS.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE AND ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT, SERVICES AND TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR  POLICIES. ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast RESERVES THE RIGHT TO TERMINATE ANY ACCOUNT THAT HAS BEEN INACTIVE FOR 180 DAYS.

NeonBlast reserves the right to stop offering and/or supporting the Service or a particular game or part of the Service at any time, at which point your right to use the Service or a part thereof will be automatically terminated. In such event, NeonBlast shall not be required to provide refunds, benefits or other compensation to users in connection with such discontinued Service. Termination of your Account can include disabling your access to the Service or any part thereof including any content you submitted or others submitted.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You may terminate your Account at any time and for any reason by following the process described on our support page located at https://support.neonblast.net/ informing NeonBlast that you wish to terminate your Account. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>2. Ownership</h1>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>2.1. Games and Service</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
All rights, title and interest in and to the Service (including without limitation any games, titles, computer code, themes, objects, characters, character names, stories, dialogue, catch phrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, methods of operation, moral rights, documentation, in-game chat transcripts, character profile information, recordings of games played using a NeonBlast game client, and the NeonBlast game clients and server software) are owned by NeonBlast. NeonBlast reserves all rights, including without limitation, all intellectual property rights or other proprietary rights, in connection with its games and the Service.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
2.2. Accounts
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NOT WITHSTANDING ANYTHING TO THE CONTRARY HEREIN, YOU ACKNOWLEDGE AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN THE ACCOUNT, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE TO THE BENEFIT OF NeonBlast.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
2.3. Virtual Content
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast owns, has licensed, or otherwise has rights to use all of the content that appears in the Service or in NeonBlast games. Notwithstanding any provision to the contrary herein, you agree that you have no right or title in or to any content that appears in the Service, including without limitation the virtual items, content, features, goods, services or currency appearing or originating in any NeonBlast game, whether earned in a game or purchased from NeonBlast, or any other attributes associated with an Account or stored on the Service. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3. User Content</h1>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3.1. Submission of User Content
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
"User Content" means any communications, images, sounds, and all the material, data, and information that you upload or transmit through a NeonBlast game client or the Service, or that other users upload or transmit, including without limitation any chat text. By transmitting or submitting any User Content while using the Service, you affirm, represent and warrant that such transmission or submission is (a) accurate and not confidential or misleading; (b) not in violation of any laws, contractual restrictions or other third party rights, and that you have permission from any third party whose personal information or intellectual property is comprised in the User Content; (c) free of viruses, adware, spyware, worms or other malicious code; and (d) you acknowledge and agree that any of your personal information within such content will at all times be processed by NeonBlast in accordance with its Privacy Policy.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3.1.1. Content Screening
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast assumes no responsibility for the conduct of any user submitting any User Content and assumes no responsibility for monitoring the Service for inappropriate content or conduct. We do not, and cannot, pre-screen or monitor all User Content. Your use of the Service is at your own risk. By using the Service, you may be exposed to User Content that is offensive, indecent, or otherwise not in line with your expectations. You bear all risks associated with the use of any User Content available in connection with the Service. At our discretion, our representatives or technology may monitor and/or record your interaction with the Service or communications (including without limitation chat text) when you are using the Service.

By entering into these Terms of Service, you hereby provide your irrevocable consent to such monitoring and recording. You acknowledge and agree that you have no expectation of privacy concerning the transmission of any User Content, including without limitation chat text or voice communications.

NeonBlast reserves the right in its sole discretion to review, monitor, prohibit, edit, delete, disable access to or otherwise make unavailable any User Content (including without limitation your User Content) without notice for any reason or for no reason at any time. If at any time NeonBlast chooses, in its sole discretion, to monitor the Service, NeonBlast nonetheless assumes no responsibility for User Content and assumes no obligation to modify or remove any inappropriate User Content. We have the right, but not the obligation, in our sole discretion to edit, refuse to post, or remove any User Content.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3.2. Information Use by Other Members of the Service
</h3>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3.2.1. Public Discourse
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The Service may include various forums, blogs and chat features where you can post User Content, including your observations and comments on designated topics. NeonBlast cannot guarantee that other members will not use the ideas and information that you share. Therefore, if you have an idea or information that you would like to keep confidential and/or don't want others to use, do not post it on the Service. NeonBlast shall have no responsibility to evaluate, use or compensate you for any ideas or information you may choose to submit.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3.2.2. Responsible For Your Own Content
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You are solely responsible for the information that you post on, through or in connection with the Service and that you provide to others. NeonBlast may reject, refuse to post or delete any User Content for any or no reason, including, but not limited to, User Content that in the sole judgment of NeonBlast violates these Terms of Service.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3.2.3. Your License to NeonBlast
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You hereby grant to NeonBlast an irrevocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way, your User Content as well as all modified and derivative works thereof in connection with our provision of the Service, including marketing and promotions of the Service. You also hereby grant to NeonBlast the right to authorize others to exercise any of the rights granted to NeonBlast under these Terms of Service. You further hereby grant to NeonBlast the unconditional, irrevocable right to use and exploit your name, likeness and any other information or material included in any User Content and in connection with any User Content, without any obligation to you. Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed in any manner. NeonBlast does not claim any ownership rights in your User Content and nothing in these Terms of Service is intended to restrict any rights that you may have to use and exploit your User Content. NeonBlast has no obligation to monitor or enforce your intellectual property rights in or to your User Content. 
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
3.3. User Interactions
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You are solely responsible for your interactions with other users of the Service and any other parties with whom you interact through the Service and/or NeonBlast games. NeonBlast reserves the right, but has no obligation, to become involved in any way with these disputes. You will fully cooperate with NeonBlast to investigate any suspected unlawful, fraudulent, or improper activity, including, without limitation, granting NeonBlast access to any password-protected portions of your Account.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
If you have a dispute with one or more users, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
4. Fees and Purchase Terms
</h1>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
4.1.Purchases 
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
In the Service you may purchase, with "real world" money, a limited, personal, non-transferable, non-sublicensable, revocable right to use (a) virtual currency, including but not limited to virtual cash or diamonds, all for use in NeonBlast games; (b) virtual in-game items, content or features; and (c) other goods or services (points a – c are jointly referred to as "Virtual Items"). You are only allowed to purchase Virtual Items from us or our authorized partners through the Service, and not in any other way.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You expressly agree that we will start the performance of the purchase agreement of Virtual Items or any other digital content before the expiration of any statutory withdrawal period defined in applicable laws. You give explicit prior consent that the content you have purchased will be delivered to you before the withdrawal period ends and you thereby loose the statutory right of withdrawal.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast may manage, regulate, control, modify or eliminate Virtual Items at any time, with or without notice. To the fullest extent under applicable law, NeonBlast shall have no liability to you or any third party in the event that NeonBlast exercises any such rights.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The transfer of Virtual Items is prohibited except where expressly authorized in the Service. Other than as expressly authorized in the Service, you shall not sell, purchase, redeem or otherwise transfer Virtual Items to any person or entity or attempt any of the aforesaid, including but not limited to NeonBlast, another user or any third party.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND NON-REFUNDABLE EXCEPT WHERE REQUIRED UNDER APPLICABLE LAW. Please see our Parents Guide for more information regarding purchases made by minors.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The provision of Virtual Items for use in NeonBlast games is a service provided by NeonBlast that commences immediately upon acceptance by NeonBlast of your purchase.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
4.2. Payment of Fees
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You agree to pay all fees and applicable taxes incurred by you or anyone using an Account registered to you. NeonBlast may revise the pricing for the Virtual Items offered through the Service at any time. YOU ACKNOWLEDGE THAT NeonBlast IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
5. Updates to the Service
</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You understand that the Service is an evolving one. NeonBlast may require that you accept updates to the Service and to NeonBlast's games you have installed on your device or computer. You acknowledge and agree that NeonBlast may update the Service and NeonBlast games, with or without notifying you. You may need to update third party software from time to time in order to receive the Service and play NeonBlast games. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
6. Disclaimer of Warranties
</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
WITHOUT LIMITING NeonBlast'S LIABILITY UNDER SECTION 7 BELOW, THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. NeonBlast DOES NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.

Some jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above disclaimers may not apply to you. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
7. Limitation of Liability; Sole and Exclusive Remedy; Indemnification
</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
TO THE MAXIMUM EXTENT PERMITTED BY LAW, NeonBlast SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR THE SERVICE ITSELF, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT NeonBlast HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW, NeonBlast SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID TO NeonBlast IN ACCORDANCE WITH THESE TERMS OF SERVICE IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID ANYTHING TO NeonBlast DURING SUCH TIME PERIOD, YOUR SOLE REMEDY (AND NeonBlast'S EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH NeonBlast IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT. 
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF NeonBlast OR FOR DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD OF NeonBlast.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You agree to indemnify, defend and hold NeonBlast (and our officers, directors, agents, subsidiaries, joint ventures and employees) harmless from any claim, demand, damages or other losses, including reasonable attorneys' fees, asserted by any third-party resulting from or arising out of your use of the Service, or any breach by you of these Terms of Service, however the foregoing does not apply if the infringement of rights is not attributable to your intentional or negligent behavior. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8. Dispute Resolution
</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You and NeonBlast agree that the processes for dispute resolution described in this agreement will apply to any dispute or claims related to these Terms of Service, the Privacy Policy, or the Service. Disputes include any claims of any kind, including but not limited to legal, equitable, or statutory claims. Processes for dispute resolution will apply even if you stop using your Account, delete your Account, or stop using the Service. They will also apply to disputes that arose before we entered into this agreement.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Sections 8.1 to 8.6 apply to you if you are a resident of the United States or Canada. They do not apply to you if you are a resident of any other country.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8.1. Informal Dispute Resolution
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You must try to informally resolve any dispute directly with NeonBlast for at least thirty (30) days before you start an arbitration. The informal dispute resolution process starts when you give NeonBlast written notice of the dispute through legal@neonblast.net.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8.2. Arbitration
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Agreement You and NeonBlast agree to resolve any disputes exclusively in final and binding arbitration as follows:
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Either you or NeonBlast may choose to submit any dispute for resolution exclusively by final and binding arbitration unless the claim is within the exceptions described below. If you or NeonBlast brings a claim in court that can be resolved by arbitration under this section, then either party can ask the court to order the parties to resolve the claim by arbitration. The arbitrator will have the exclusive authority to decide whether any portion of Section 8 (“Dispute Resolution”) is valid or enforceable, or whether it applies to a claim.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
An arbitration proceeding will be held before a neutral arbitrator. This means you and NeonBlast agree to give up the right to resolve the dispute in a trial before a judge or jury. Arbitration has different rules than more formal lawsuits. For example, the ability to force the other side to share information may be more limited than the process called discovery in formal lawsuits. After the arbitrator decides the outcome, that decision will be final. You or NeonBlast can ask the arbitrator to put a decision or award and the reasons for it in writing. Either of us can ask a court to confirm or enter the arbitrator’s final decision or award, which will make it the same as a court judgment. You and NeonBlast will generally not be able to change the outcome of arbitration through courts outside of very limited circumstances. 
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8.3. Arbitration Process
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The arbitration will be run by the American Arbitration Association (“AAA”). AAA’s rules and procedures will be used for the arbitration, including the Consumer Arbitration Rules. But if there is a conflict between these Terms of Service and AAA’s rules and procedures, then we will follow these Terms of Service. To review AAA’s Rules or to start arbitration, you can go to AAA’s website. If either of us decide to start arbitration, we agree to provide the other party with a written Demand for Arbitration as specified in the AAA Rules.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The fees for arbitration will be determined by AAA’s Consumer Arbitration Rules. If the arbitrator decides that those fees are excessive, NeonBlast will pay the fees. Each side will pay their own attorneys’ fees and costs unless the claims allow for to the prevailing party to recover attorneys’ fees and costs, in which case the arbitrator may award them under the applicable law. If either party unsuccessfully challenges the validity of the arbitrator’s decision or award through a subsequent court case, the unsuccessful party shall pay the opposing party’s costs and attorneys’ fees associated with the challenge.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The arbitration will take place either in San Francisco, California, USA or in the county or province where you reside.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8.4 Exceptions to Agreement to Arbitrate
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You and NeonBlast agree that the arbitration agreement in Section 8.2 will not apply to the following disputes:

Claims about NeonBlast’s intellectual property, such as claims to enforce, protect, or concerning the validity of NeonBlast’s copyrights, trademarks, trade dress, domain names, patents, trade secrets, or other intellectual property rights.

Claims related to piracy or tortious interference.

Claims that are not subject to an arbitration agreement as a matter of law and are not preempted by federal law that would allow for an agreement to arbitration.

Claims in small claims court.

Any dispute not subject to arbitration under these exceptions shall be resolved by a court of competent jurisdiction as described in Section 10 (“Venue for Disputes Not Subject to Arbitration”).
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8.5 No Class Actions
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You and NeonBlast agree that we can only bring claims against each other on an individual basis.That means:

You cannot bring a claim against NeonBlast as a plaintiff or class member in a class, collective, consolidated, or representative action.

The arbitrator cannot combine any other person’s claims with yours into a single case or preside over any class, collective, consolidated, or representative arbitration proceeding.

The arbitrator’s decision or award in your case will not apply to anyone else and cannot be used to decide other people’s disputes.

If this section (Section 8.5 “No Class Actions”) is found to be unenforceable or invalid, then the entirety of Section 8, including Sections 8.1 to 8.6, shall be void.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8.6 Opt-Out of Arbitration Agreement and No Class Actions Provisions
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You can choose to opt out of and not be bound by the Arbitration Agreement and No Class Action provisions above (Sections 8.1 to 8.5) by sending written notice of your decision to opt out to legal@neonblast.net with the subject line “ARBITRATION AND CLASS ACTION WAIVER OPT-OUT.” You must send us this notice within thirty (30) days of your first use of the Service or availability of this opt-out, whichever is later. If you do not send us a notice within that time, you will be bound to arbitrate disputes in accordance with the terms of these paragraphs. If you opt out of these arbitration provisions, NeonBlast also will not be bound by them.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
8.7 Alternative and Online Dispute Resolution
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
If you are a consumer based in the EU or EEA, you may make use of the Online Dispute Resolution platform  offered by the European Commission as an out-of-court alternative to resolve disputes.  You may also recourse to alternative dispute resolution and refer a dispute to the consumer disputes board or other corresponding out-of-court institution in your domicile.
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
9 Applicable Law
</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
If you are a resident of the United States or Canada: (1) the United States Federal Arbitration Act (including its procedural provisions) governs the interpretation and enforcement of the agreement to arbitrate and no class actions provisions of Section 8, and (2) any dispute arising out of or related to these Terms of Service, the Privacy Policy, or the Service shall be governed in all respects by the laws of the State of California, USA without regard to conflict of law provisions. 

If you are a resident outside of the United States and Canada, you agree that any claim or dispute arising out of or related to these Terms of Service, the Privacy Policy, or the Service shall be governed by the laws of Finland without regard to conflict of law provisions. 

10 Venue for Disputes Not Subject to Arbitration
If you are a resident of the United States or Canada, you agree that any claim or dispute you may have against NeonBlast that is not subject to arbitration under Section 8 must be resolved exclusively by a federal or state court located in San Francisco, California. You and NeonBlast consent to venue and personal jurisdiction in San Francisco, California for all such claims or disputes.

If you are a resident outside of the United States and Canada, you agree that any claim or dispute you may have against NeonBlast must be resolved exclusively by a court located in Helsinki, Finland. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
11. Severability
</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You and NeonBlast agree that if any portion of these Terms of Service or of the NeonBlast Privacy Policy is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of the terms, which shall continue to be in full force and effect. 
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12. General Provisions
</h1>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12.1. Assignment
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast may assign or delegate these Terms of Service and/or the NeonBlast Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without NeonBlast's prior written consent, and any unauthorized assignment and delegation by you is ineffective.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12.2. Supplemental Policies
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast may publish additional policies related to specific services such as forums, contests, or loyalty programs. Your right to use such services is subject to those specific policies and these Terms of Service.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12.3. Entire Agreement
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
These Terms of Service, any supplemental policies and any documents expressly incorporated by reference herein (including the NeonBlast Privacy Policy), contain the entire understanding of you and NeonBlast, and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us with respect to the Service.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12.4. No Waiver
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The failure of NeonBlast to require or enforce strict performance by you of any provision of these Terms of Service or the NeonBlast Privacy Policy or failure to exercise any right under them shall not be construed as a waiver or relinquishment of NeonBlast's right to assert or rely upon any such provision or right in that or any other instance. 

The express waiver by NeonBlast of any provision, condition, or requirement of these Terms of Service or the NeonBlast Privacy Policy shall not constitute a waiver of any future obligation to comply with such provision, condition or requirement.

Except as expressly and specifically set forth in this these Terms of Service, no representations, statements, consents, waivers, or other acts or omissions by NeonBlast shall be deemed a modification of these Terms of Service nor legally binding, unless documented in physical writing, hand signed by You and a duly appointed officer of NeonBlast.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12.5. Notices
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
We may notify you via postings in our games, on neonblast.net, via e-mail or any other communications means to contact information you provide to us. All notices given by you or required from you under these Terms of Service or the NeonBlast Privacy Policy shall be in writing and addressed to: NeonBlast Oy. Attn: Legal, Jätkäsaarenlaituri 1, FI- 00180 Helsinki, Finland, with a copy to legal@neonblast.net.
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12.6. Equitable Remedies
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You acknowledge that the rights granted and obligations made under these Terms of Service to NeonBlast are of a unique and irreplaceable nature, the loss of which shall irreparably harm NeonBlast and which cannot be replaced by monetary damages alone so that NeonBlast shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you. 

You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Service or any NeonBlast game, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service and agree to limit your claims to claims for monetary damages, limited by Section 7 (if any).
</p>
<h3 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>
12.7. Force Majeure
</h3>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
NeonBlast shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of NeonBlast, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond NeonBlast's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
</p>        
</div>
            </div>
            </div>


          {/* call footer */}
          <Footer condition={false} bg={"#FFFF"} />
        </div>
      </section>
    </>
  );
};

export default TermsandConditions;
